<template>
  <div class="sheet a4">
    <div class="sheet-top">
      <Header />

      <InformationCustomer />
    </div>

    <div class="sheet-content">
      <slot></slot>
    </div>

    <div style="flex: 1 1 0"></div>

    <Insurance :site="saleReceipt.site" />

    <Footer :site="saleReceipt.site" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Header from "@/modules/Print/components/Header.vue";
import Footer from "@/modules/Print/components/Footer.vue";
import InformationCustomer from "@/modules/Print/components/InformationCustomer.vue";
import Insurance from "@/modules/Print/components/Insurance.vue";

export default defineComponent({
  components: { Insurance, InformationCustomer, Footer, Header },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  }
});
</script>
