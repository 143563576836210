<template>
  <div
    v-if="saleReceipt.khachhang"
    class="sheet-information-customer d-flex justify-space-between"
  >
    <div class="pr-6">
      <div class="sheet-font--bold">THÔNG TIN KHÁCH HÀNG</div>
      <div>
        <span class="sheet-font--bold">Họ & Tên: </span>
        <span>{{ saleReceipt.khachhang.name }}</span>
      </div>
      <div>
        <span class="sheet-font--bold">SĐT: </span>
        <span v-if="saleReceipt.khachhang.phone">
          {{ saleReceipt.khachhang.phone | VMask("### ### ####") }}</span
        >
      </div>
      <div>
        <span class="sheet-font--bold">Email: </span>
        <span>{{ saleReceipt.khachhang.email }}</span>
      </div>
      <div>
        <span class="sheet-font--bold">Địa chỉ: </span>
        <span>{{ getAddressText(saleReceipt) }}</span>
      </div>
    </div>
    <div class="sheet-signature sheet-text--center" style="max-width: 276px">
      <div class="px-3" style="max-width: 235px">
        (Chữ kỹ xác nhận đã được NVBH phổ biến quy định bảo hành & đổi trả)
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LIST_MODEL_SITES } from "@/core/constant";
import { formatDateTime } from "@/core/utils";
import { getAddressText } from "@/core/composables";

export default defineComponent({
  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  },

  methods: {
    formatDateTime,

    shortenCode(code) {
      return code.split("-")[0];
    },

    getAddressText() {
      return getAddressText(this.saleReceipt.khachhang.address[0]);
    }
  }
});
</script>

<style scoped>
.page-footer {
  position: relative;
}
</style>
