<template>
  <div class="sheet-footer page-footer">
    <div class="sheet-divider my-3"></div>
    <!-- Start: Contacts -->
    <div class="d-flex sheet-contact">
      <div class="col sheet-font--bold pa-0">LIÊN HỆ VỚI CHÚNG TÔI</div>
      <div class="col pa-0"><strong>Hotline:</strong> 1900 633 579</div>
      <div class="col sheet-text--center pa-0">
        <strong>Website:</strong>
        {{
          saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id
            ? "goodspace.art"
            : "thinkpro.vn"
        }}
      </div>
      <div class="col sheet-text--right pa-0">
        <strong>Fanpage:</strong>
        {{
          saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id
            ? "fb.com/goodspace.art"
            : "fb.com/thinkpro.vn"
        }}
      </div>
    </div>
    <!-- End: Contacts -->
    <div class="sheet-divider my-3"></div>
    <!-- Start: Stores -->
    <div class="d-flex sheet-stores">
      <div class="col-3 sheet-font--bold pa-0">HỆ THỐNG CỬA HÀNG</div>
      <div class="col-4 pa-0">
        <div class="d-flex">
          <div class="sheet-font--bold">Hà Nội:</div>
          <ul>
            <li><span class="text-back">53 Thái Hà, Q. Đống Đa</span></li>
          </ul>
        </div>
      </div>
      <div class="col-5 pa-0">
        <div class="d-flex justify-end">
          <div class="sheet-font--bold">TP.HCM:</div>
          <ul>
            <li>
              <div class="text-back">
                5-7 Nguyễn Huy Tưởng, P. 6, Q. Bình Thạnh
              </div>
            </li>
            <li>
              <div class="text-back">95 Trần Thiện Chánh, P. 12, Q. 10</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End: Stores -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LIST_MODEL_SITES } from "@/core/constant";

export default defineComponent({
  data() {
    return {
      LIST_MODEL_SITES,
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
  },
});
</script>

<style scoped>
.page-footer {
  position: relative;
}
</style>
