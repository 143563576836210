<template>
  <div class="sheet-insurance">
    <div class="sheet-divider my-3"></div>
    <!-- Start: More info -->
    <div class="sheet-info">
      <!-- Start: Warranty -->
      <div>
        <div class="sheet-font--bold">QUY ĐỊNH BẢO HÀNH</div>
        <ul>
          <li class="sheet-info--text-content">
            <div class="text-back">
              Máy sử dụng không đúng như mong đợi của khách hàng sẽ được hỗ trợ
              đổi sang model khác, tuy nhiên sẽ mất phí đổi/hoàn máy theo chính
              sách đổi/hoàn máy tại cửa hàng.
            </div>
          </li>
          <li class="sheet-info--text-content">
            <div class="text-back">
              <div>Những vấn đề sau sẽ KHÔNG nằm trong phạm vi bảo hành:</div>
              <div>
                <div class="d-flex">
                  <div class="col-auto py-0 pl-0 pr-2">
                    <div>
                      1. Sản phẩm bị lỗi hình thức, biến dạng, mờ, mòn chữ, v.v
                    </div>
                    <div>
                      2. Sản phẩm đã hết thời hạn bảo hành tại
                      {{ nameCompany }}.
                    </div>
                  </div>
                  <div class="col-auto py-0 pl-0 pr-1">
                    <div>3. Khách hàng đã tự ý tháo/dỡ sản phẩm.</div>
                    <div>
                      4. Sản phẩm không có tem của {{ nameCompany }}/tem bị
                      rách.
                    </div>
                  </div>
                  <div class="col sheet-text--right pa-0">
                    <div>5. Màn hình sáng không đều (Đặc tính riêng</div>
                    <div>của một số loại màn hình, không phải lỗi).</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- End: Warranty -->
      <!-- Start: Refund and return -->
      <div class="mt-3">
        <div class="sheet-font--bold">QUY ĐỊNH ĐỔI TRẢ</div>
        <ul>
          <li class="sheet-info--text-content">
            <div class="text-back">
              Sản phẩm đổi trả phải giữ nguyên 100% ngoại hình ban đầu, gồm vỏ
              hộp, giấy tờ liên quan và đủ điều kiện bảo hành của hãng.
            </div>
          </li>
          <li class="sheet-info--text-content">
            <div class="text-back">
              Thân máy và màn hình máy không bị trầy xước (áp dụng cho Laptop,
              Màn hình máy tính & Máy tính để bàn).
            </div>
          </li>
        </ul>
      </div>
      <!-- End: Refund and return -->
      <!-- Start: Other -->
      <div class="mt-3 d-flex">
        <div class="col-8 pa-0">
          <div class="sheet-font--bold">SẢN PHẨM HỎNG GÌ ĐỔI NẤY</div>
          <ul>
            <li class="sheet-info--text-content">
              <div class="text-back">
                Đối với sản phẩm chính: Đổi 1-1 cùng model, cấu hình, màu sắc,
                v.v (chi tiết sẽ được nhân viên hướng dẫn theo trường hợp).
              </div>
            </li>
            <li class="sheet-info--text-content">
              <div class="text-back">
                Đối với phụ kiện: Đổi phụ kiện có cùng công năng mà
                {{ nameCompany }} đang kinh doanh (trường hợp không có phụ kiện
                tương đương hoặc khách hàng không đồng ý, {{ nameCompany }} sẽ
                áp dụng bảo hành hãng).
              </div>
            </li>
          </ul>
        </div>
        <div class="col-4 pa-0">
          <div class="d-flex mx-1">
            <div class="mx-4">
              <div class="sheet-font--bold">PHẢN HỒI, GÓP Ý, KHIẾU NẠI</div>
              <div class="my-2 sheet-qr">
                QUÉT QR CODE
                <span>
                  <v-icon size="18px">mdi-arrow-right</v-icon>
                </span>
              </div>
            </div>
            <img alt="feedback qr-code" src="../assets/images/qr-code.svg" />
          </div>
        </div>
      </div>
      <!-- End: Other -->
    </div>
    <!-- End: More info -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LIST_MODEL_SITES } from "@/core/constant";

export default defineComponent({
  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    nameCompany() {
      if (this.saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id) {
        return LIST_MODEL_SITES.GOODSPACE.text;
      } else if (this.saleReceipt.site === LIST_MODEL_SITES.THINKPRO.id) {
        return LIST_MODEL_SITES.THINKPRO.text;
      }
    }
  }
});
</script>
