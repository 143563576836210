<template>
  <div class="sheet-header">
    <div class="d-flex align-center justify-space-between">
      <div>
        <div class="sheet-logo--container">
          <img
            v-if="saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id"
            alt="Logo"
            class="pb-3"
            src="../assets/images/logo-goodspace.svg"
            width="120px"
          />
          <img
            v-else
            alt="Logo"
            src="../assets/images/logo-thinkpro.svg"
            width="128.6px"
          />
        </div>
        <div class="mt-2" style="max-width: 276px">
          <div class="sheet-font--bold">Chi nhánh</div>
          <div>
            {{ saleReceipt.branch && saleReceipt.branch.address }}
          </div>
        </div>
      </div>
      <div>
        <div class="sheet-title sheet-font--bold">HÓA ĐƠN BÁN LẺ</div>
        <div class="mt-2">
          <div class="d-flex align-center justify-space-between">
            <div>Số hóa đơn</div>
            <div class="sheet-font--bold">
              {{ shortenCode(saleReceipt.code) }}
            </div>
          </div>
          <div class="d-flex align-center justify-space-between">
            <div>Ngày ghi</div>
            <div class="sheet-font--bold">
              {{ formatDateTime(saleReceipt.created_at) }}
            </div>
          </div>
          <div class="d-flex align-center justify-space-between">
            <div>NVBH</div>
            <div class="sheet-font--bold">
              {{ saleReceipt.created_user && saleReceipt.created_user.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sheet-divider my-4"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LIST_MODEL_SITES } from "@/core/constant";
import { formatDateTime } from "@/core/utils";

export default defineComponent({
  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  },

  methods: {
    formatDateTime,

    shortenCode(code) {
      if (!code) return "";

      const codes = code.split("-");

      return codes.length > 1 ? codes[0] : code;
    }
  }
});
</script>

<style scoped>
.page-footer {
  position: relative;
}
</style>
