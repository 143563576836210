<template>
  <div>
    <Sheet>
      <div class="sheet-products">
        <!-- Start: Products list -->
        <table>
          <thead>
            <tr>
              <th class="text-left">SẢN PHẨM</th>
              <th>ĐƠN GIÁ</th>
              <th>CHIẾT KHẤU</th>
              <th>SỐ LƯỢNG</th>
              <th>THÀNH TIỀN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in saleReceiptOption" :key="item.id">
              <td>
                <div class="sheet-font--bold">
                  {{ item.product && item.product.name }}
                </div>
                <div>
                  Serial:
                  <span v-for="(serial, index) in item.serials" :key="serial">
                    {{ serial
                    }}<template v-if="index < item.serials.length - 1"
                      >,</template
                    >
                  </span>
                </div>
              </td>
              <td class="sheet-text--center">
                {{ item.unit_sold_price | formatCurrency("VND") }}
              </td>
              <td class="sheet-text--center">
                {{
                  (item.unit_sold_price - item.sold_price)
                    | formatCurrency("VND")
                }}
              </td>
              <td class="sheet-text--center">
                {{ item.serials.length }}
              </td>
              <td class="sheet-text--right">
                {{
                  (item.sold_price * item.serials.length)
                    | formatCurrency("VND")
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End: Products  list-->

        <div style="page-break-inside: avoid">
          <div class="sheet-divider my-3"></div>
          <!-- Start: Amounts -->
          <div class="d-flex justify-end">
            <div class="sheet-amounts">
              <div class="d-flex justify-space-between">
                <div>Tổng tiền hàng</div>
                <div>
                  {{ Number(saleReceipt.goods_value) | formatCurrency("VND") }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Phí vận chuyển</div>
                <div>
                  {{
                    saleReceipt.phivanchuyen
                      ? saleReceipt.phivanchuyen
                      : 0 | formatCurrency("VND")
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Phí xử lý đơn hàng</div>
                <div>
                  {{
                    saleReceipt.handle_fee
                      ? saleReceipt.handle_fee
                      : 0 | formatCurrency("VND")
                  }}
                </div>
              </div>

              <div class="d-flex justify-space-between">
                <div>Giảm giá hóa đơn</div>
                <div>
                  -{{
                    saleReceipt.promo
                      ? saleReceipt.promo.money
                      : 0 | formatCurrency("VND")
                  }}
                </div>
              </div>
              <div
                v-if="saleReceipt.return_goods_value"
                class="d-flex justify-space-between"
              >
                <div>Giảm trừ hàng trả</div>
                <div>
                  {{ saleReceipt.return_goods_value | formatCurrency("VND") }}
                </div>
              </div>
            </div>
          </div>
          <!-- End: Amounts -->
          <div class="sheet-divider my-3"></div>
          <!-- Start: Total -->
          <div class="d-flex flex-column align-end">
            <div
              class="d-flex justify-space-between sheet-font--bold sheet-total text-large"
            >
              <div>Tổng cộng</div>
              <div>
                {{ saleReceipt.value | formatCurrency("VND") }}
              </div>
            </div>
          </div>
          <!-- End: Total -->
        </div>
        <!-- End: Products -->
      </div>
    </Sheet>
  </div>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";
import Sheet from "@/modules/Print/components/Sheet.vue";

const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

export default {
  components: { Sheet },

  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptProcessingThuTotal() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptProcessingThuTotal"];
    },
    saleReceiptOption() {
      const saleReceipt = JSON.parse(JSON.stringify(this.saleReceipt));
      const serialsArr = saleReceipt.serials;
      let options = [];

      if (saleReceipt && serialsArr && serialsArr.length > 0) {
        // Group by option_id value
        options = groupBy(["option_id"])(serialsArr).filter(
          item => item !== null
        );

        // Map results
        options = options.map(option => {
          return {
            id: option[0].option_id,
            SKU: option[0].option_sku,
            name: option[0].option_name,
            product: {
              name: option[0].product_name
            },
            unit_sold_price: Number(option[0].unit_sold_price),
            sold_price:
              option[0].sold_price !== null &&
              option[0].sold_price !== undefined
                ? Number(option[0].sold_price)
                : Number(option[0].unit_sold_price),
            serials: option.map(item => item.serial_number)
          };
        });
      }

      return options;
    },
    saleReceiptPaymentAmount() {
      return this.saleReceiptVouchers.reduce(
        (total, item) => total + item.assigned_amount,
        0
      );
    },
    saleReceiptVouchers() {
      if (this.saleReceipt && this.saleReceipt.id) {
        const arr = this.saleReceipt.fake_vouchers.filter(
          voucher => voucher.status === 0
        );

        return this.saleReceipt.vouchers.concat(arr);
      }

      return [];
    }
  },

  async mounted() {
    const route = this.$route;

    await this.$store.dispatch(
      "SALE_RECEIPT/getSaleReceiptById",
      route.params.saleReceiptId
    );

    if (this.saleReceipt) {
      await this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        this.saleReceipt.id
      );
    }

    this.printPage();
  },

  methods: {
    printPage() {
      this.$nextTick(() => {
        window.print();
      });
    }
  }
};
</script>
